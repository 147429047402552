header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}


/* CTA */

.cta {
    margin-top: 2.5rem;
    display: flex;
    grid-area: 1.2rem;
    justify-content: center;
}

/* Header Social */

.header_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_social::after {
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/* ME */



.me {
    background-color: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 35%;
    margin-top: -4rem;
    align-items: center;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* Scroll Down */

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/*  Media query for Medium size device - tablet*/

@media screen and (max-width:1024px) {
    header {
        height: 64vh;
    }
}


/*  Media query for small size device - mobile */
@media screen and (max-width:600px) {
    .header {
        height: 100vh;
    }

    .header_social,
    .scroll_down {
        display: none;
    }
    .me {
        width: 90%; 
        height: auto; 
        max-width: 22rem; 
        margin-left: -30%;
        padding-top: 3rem;
        padding-bottom: 3rem;
        
    }
    
}