.about_container{
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about_me{
  width: 100%;
  /* aspect-ratio: 1/1; */
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent);
    display: grid;
    place-items: center;
}


.about_me-image{
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition)
}

.about_me-image:hover{
  transform: rotate(0deg) ;
}

.about_cards{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

.about_card{
  background: #2B2B6A;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem 1rem; 
  text-align: center;
  transition: var(--transition);
}

.about_card:hover{
  /* background: transparent; */
  background-color: var(--color-primary-variant);
border-color: rgba(77, 181, 255, 0.4);
  cursor: default;
}

.about_icon{
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about_card h5{
  font-size: 0.95rem;
}

.about_card small{
  font-size: 0.8rem;
}

.about_content p{
  margin: 2rem 0 1.5rem;
  color: var(--color-light);
}



@media screen and (max-width: 1024px) {
  .about_container {
    grid-template-columns: 1fr; 
    gap: 0;
  }
  
  .about_me {
    width: 50%;
    margin: 2rem auto 4rem;
  }
  
  .about_content p{
    margin: 1rem 0 1.5rem;
  }

  /* .about_cards {
    grid-template-columns: repeat(2, 1fr); 
  } */
}

@media screen and (max-width: 600px) {
  
  .about_cards {
    grid-template-columns: 1fr;
    padding: 15%; 
    gap: 1rem; 
  }

  .about_me{
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about_content{
    text-align: center;
  }

  .about_container{
    margin: 1.5rem 0;
  }

}