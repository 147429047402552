.experience_container{
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 2rem;
}

.experience_container > div{
  background-color: #2c2c6c;
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience_container > div:hover{
  background: transparent;
  border-color: rgba(77, 181, 255, 0.4);
  cursor: default;
}

.experience_container > div h3{
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience_content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience_details{
  display: flex;
  gap: 1rem;
}

.experience_details-icons{
  /* width: 2rem;
  height: 2rem; */
  margin-top: 6px;
  color: var(--color-primary);
}

.experience_details-icons:hover{
  color: #2c2c6c;
}
.space{
  height: 50px;
}

@media screen and (max-width: 600px) {

  .experience_container{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 2rem;

  }

  .experience_container > div{
  padding: 2rem;
  }
}